const state = {
    selectedBusiness : {
        alias : '',
        modules : [],
        managerPermissions : [],
        userPermissions : {
            permissions : [],
            modulePermissionsObject : {}
        }
    }
}
const actions = {
    async AJAX_SEND({rootState,}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.business) {
                params.business = body.business
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                return data.error
            }

        } catch (e) {
            console.log(e)
            return e.error
        }
    },
    async AJAX_SEND_NEW({rootState}, body) {
        try {
            rootState.LOADING = true;
            var params = {
                route  :  body.route,
                method :  body.method,
                token  :  body.token
            }

            if (body.data) {
                params['body'] = body.data
            }

            if (body.business) {
                params.business = body.business
            }

            const data = await rootState.$ajax_new(params);
            rootState.LOADING = false;

            if (!data) { console.log(data)
            }
            return data


        } catch (e) {
            rootState.LOADING = false;
            return e.error
        }
    },

    // MAIN EDASHBOARD

    async GET_USER_BUSINESSES({dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`businesses/user`,
                method:'GET'
            },{ root : true } );
    },


    // BUSINESS MANAGE
    async ADD_BUSINESS({dispatch,rootState},body) {

        if ( rootState.adminPartners.partnerReferral ) {
            body.referral = rootState.adminPartners.partnerReferral
            localStorage.removeItem('pref')
        }

        body.project = rootState.project
        return await dispatch('AJAX_SEND_NEW',{token:rootState.auth.accessToken,route:`business`, method:'POST',data: body});
    },
    async GET_BUSINESS_DASHBOARD({dispatch,rootState},body) {


        let request = await dispatch('AJAX_SEND',{ token:rootState.auth.accessToken,route:`business/dashboard/${body}`, method:'GET' });

        state.selectedBusiness = request.result ? request.data : {}
        state.selectedBusiness.emailClientAddress = `${state.selectedBusiness.alias}@westudy.ua`
        state.selectedBusiness = JSON.parse(JSON.stringify(state.selectedBusiness))

        rootState.dashboard.showBusinessToOwner   = rootState.auth.userBusinesses.includes(request.data.alias)
        rootState.dashboard.showBusinessToManager = rootState.auth.userManagingBusinesses.includes(request.data.alias)
        rootState.templates.products = request.data.products
        rootState.cart.deliveries = []
        rootState.cart.payments = []
        if ( request.data.deliveries) {
            request.data.deliveries.forEach( (delivery)=>{
                rootState.cart.deliveries.push({text:delivery.name,value:delivery.id})
            })
        }
        if (request.data.payments) {
            request.data.payments.forEach( (payment)=>{
                rootState.cart.payments.push({text:payment.name,value:payment.id})
            })
        }

        rootState.templates.businessAlias = request.data.alias;
        rootState.templates.product_categories = request.data.product_categories
        rootState.templates.locations = request.data.locations
        rootState.templates.deliveryAllowCountries = request.data.deliveryAllowCountries
        rootState.templates.businessCountry = request.data.country
        rootState.templates.deliveryCountries = request.data.deliveryCountries

        if ( rootState.auth.loggedIn ) {
            rootState.dashboard.showBusinessToOwner   = rootState.auth.userBusinesses.includes(request.data.alias)
            rootState.dashboard.showBusinessToManager = rootState.auth.userManagingBusinesses.includes(request.data.alias)
        }
    },
    async GET_BUSINESS({dispatch,rootState},body) {

        if (rootState.templates.editorMode) {
            return await dispatch('AJAX_SEND',{
                token:rootState.auth.accessToken,
                route:`business/admin`,
                method:'GET',
                business : body,});
        } else {
            return await dispatch('AJAX_SEND',{
                token:'public',
                route:`business/public/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET' });
        }

    },
    async GET_BUSINESS_PUBLIC({dispatch},body) {

        let business = await dispatch('ajax/SEND_NEW',{
            token:'public',
            route:`business/public/${body}`,
            method:'GET'
        },  {root:true});

        if (!business) {
            return false
        }

        await dispatch('PREPARE_BUSINESS_DATA',business)

        return business

    },

    async PREPARE_BUSINESS_DATA({state,rootState},business) {
        state.selectedBusiness = business

        if ( business.deliveries) {
            rootState.cart.deliveries = []
            business.deliveries.forEach( (delivery)=>{
                rootState.cart.deliveries.push({text:delivery.name,value:delivery.id})
            })
        }

        if (business.payments) {
            rootState.cart.payments = []
            business.payments.forEach( (payment)=>{
                rootState.cart.payments.push({text:payment.name,value:payment.id})
            })
        }

    },

    async CHECK_ALIAS({dispatch},body) {

        return await dispatch('ajax/SEND_NEW',{
                route:`business/check_alias/${body}`,
                method:'GET' },
                { root : true });

    },
    async GET_CLIENT_PLANS({dispatch},body) {

        return await dispatch('ajax/SEND_NEW',{
                route:`business_plans/${body}`,
                method:'GET' },
                { root : true });

    },

    // BUSINESS DASHBOARD
    async GET_DASHBOARD_BUSINESS({dispatch},body) {
        return await dispatch('AJAX_SEND',{token:'public',route:`business/${body}`, method:'GET'});
    },

    // BUSINESS SETTINGS GENERAL
    async GET_GENERAL_SETTINGS({rootState,dispatch},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`options/general/${body}`, method:'GET'});
    },
    async SAVE_GENERAL_SETTINGS({rootState,dispatch},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`business/${body.alias}`, method:'PUT', data:body.data});
    },
    // PAYMENTS
    async GET_PAYMENT_TYPES({dispatch,rootState}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`options/payments`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true});
    },
    async EDIT_PAYMENT_TYPES({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`options/payment`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body
            }, {root : true});

    },
    // BUSINESS  DELIVERY
    async GET_DELIVERY_TYPES({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken ,route:`options/delivery/${body}`, method:'GET'});
    },
    async EDIT_DELIVERY_TYPES({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken ,route:`options/delivery/${body.alias}`, method:'PUT', data: body.data});
    },

    // BUSINESS  PAGES
    async GET_PAGES_ALL({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{ token: rootState.auth.accessToken,route:`pages/${body}`, method:'GET'});
    },
    async GET_PAGES_LIST({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{ token: rootState.auth.accessToken,route:`pages/${body}/list`, method:'GET'});
    },
    async GET_PAGE_PUBLIC({dispatch,rootState},body) {
        if (rootState.templates.editorMode) {
            return await dispatch('AJAX_SEND',{
                token: rootState.auth.accessToken ,
                route:`page/admin`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body});
        } else {
            return await dispatch('AJAX_SEND',{ token: 'public' ,route:`page/public`, method:'POST', data : body});
        }


    },
    async GET_PAGE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{ token:rootState.auth.accessToken,route:`news/${body.business}/${body.news}/admin`, method:'GET'});
    },
    async EDIT_PAGES({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`news/${body.id}`, data: body.data, method:'PUT'});
    },

    // BUSINESS  FILES
    async GET_BUSINESS_IMAGES_LIBRARY({rootState,dispatch},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`library/images/${body}`, method:'GET'});
    },

    //MODULES
    async GET_MODULES({dispatch,rootState,state}) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`modules/${state.selectedBusiness.alias}`, method:'GET'});
    },
    async UPDATE_MODULES({dispatch,rootState,state},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`modules/${state.selectedBusiness.alias}`,data:{modules : body}, method:'POST'});
    },

    //BUSINESS SERVICES KEYS
    async UPDATE_BUSINESS_SERVICES_KEYS({dispatch,rootState,state},body) {
        return await dispatch('AJAX_SEND',{token:rootState.auth.accessToken,route:`business/${state.selectedBusiness.alias}/serviceKeys`,data:body, method:'POST'});
    },

    // technical

    RESET_BUSINESS_STATE({state}) {
        state.selectedBusiness = {
            alias : state.selectedBusiness.alias,
                modules : state.selectedBusiness.modules,
                managerPermissions : [],
                userPermissions : {
                permissions : [],
                    modulePermissionsObject : {}
            }
        }
    }

}

export default {
    namespaced:true,
    actions,
    state
}