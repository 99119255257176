export default [
    { path: '' , component: () => import("@/components/pages/homepage_westudy/PageHomepageWestudy") , children :
            [
                { path: '' ,                       component:() => import("@/components/pages/homepage_westudy/home/HomeMainWestudy")      },
                { path: 'register' ,               component:() => import("@/components/pages/homepage_westudy/register/HomepageRegister") },
                { path: 'login' ,                  component:() => import("@/components/auth/LoginPage") },
                { path: 'documentation',           component:() => import("@/components/pages/homepage_westudy/documentation/HomeDocumentationWestudy")  },
                { path: "documentation/:project/:alias?" , component:() => import("@/components/pages/homepage_westudy/documentation/HomeDocumentationWestudy") , props : true},
                { path: 'contacts',                component:() => import("@/components/pages/homepage_westudy/contacts/HomeContactsWestudy")          } ,
                { path: 'about',                   component:() => import("@/components/pages/homepage_westudy/about/HomeAboutWestudy")             } ,
                { path: 'about/offer',             component:() => import("@/components/pages/homepage_westudy/about/homeOffer")                    } ,
                { path: 'about/confidential',      component:() => import("@/components/pages/homepage_westudy/about/homeConfidentialPolycy")      } ,
                { path: 'about/refund',            component:() => import("@/components/pages/homepage_westudy/about/homeRefundRules")              } ,
                { path: 'about/terms',             component:() => import("@/components/pages/homepage_westudy/about/homeTermsAndConditions")       } ,
                { path: 'pricing',                 component:() => import("@/components/pages/homepage_westudy/about/homePricing")                  } ,
                { path: 'news/',                   component:() => import("@/components/pages/homepage/news/HomeNews")                    } ,
                { path: 'news/:alias',             component:() => import("@/components/pages/homepage/news/SingleNews") , props : true , } ,
                { path: 'online' ,                 component:() => import("@/components/westudyOnline")                } ,
                { path: 'certificate/check' ,      component:() => import("@/components/pages/homepage_westudy/certificate/checkCertificate")             } ,
                { path: 'partner_program' ,        component:() => import("@/components/pages/homepage_westudy/partnerProgram/HomeMainPartnerProgram.vue")             } ,
                { path: 'partner_program/rules' ,  component:() => import("@/components/pages/homepage_westudy/partnerProgram/HomeMainPartnerRules.vue")             } ,
                { path: 'about/payed_services',    component:() => import("@/components/pages/homepage_westudy/marketing_landings/homePayedServices.vue")                    } ,
                { path: 'page/:pageAlias' ,        component:() => import("@/components/pages/homepage_westudy/westudyAvalonPage.vue"), props : true      },

            ]
    }
]




