import {mapState} from "vuex";
import wsUserData from "@/components/UI/wsUserData";
import wsTextFieldNew from "@/components/UI/ws-text-field-new";
import wsInputSelector from "@/components/UI/wsInputSelector";
import wsNewUserInput from "@/components/UI/wsNewUserInput";
import wsDeleteDialog from "@/components/UI/wsDeleteDialog";
import wsEntityDialog from "@/components/UI/wsEntityDialog";
import wsIcon from "@/components/UI/wsIcon";
import wsLogo from "@/components/UI/wsLogo";
import wsTimer from "@/components/UI/wsTimer";
import wsSystemColorSelect from "@/components/UI/wsSystemColorSelect.vue";
import wsStatRow from "@/components/UI/wsStatRow.vue";
import wsButtonPanel from "@/components/UI/wsButtonPanel.vue";
import wsAccordionVariant from "@/components/UI/wsAccordionVariant.vue";


export default {
    components : {
        wsAccordionVariant,
        wsButtonPanel,
        wsStatRow,
        wsIcon,
        wsNewUserInput,
        wsUserData,
        wsTextFieldNew,
        wsInputSelector,
        wsDeleteDialog,
        wsEntityDialog,
        wsSystemColorSelect,
        wsLogo,
        wsTimer,
        wsImg : () => import("@/components/UI/wsImg"),
        wsNavigation : () => import("@/components/UI/wsNavigation"),
        wsLink : () => import("@/components/UI/wsLink"),
        wsButton : () => import("@/components/UI/wsButton"),
        wsSlider : () => import("@/components/UI/wsSlider"),
        wsButtonGroup : () => import("@/components/UI/wsButtonGroup"),
        wsStorageFilePicker : () => import("@/components/pages/businessDashboard/UI/wsStorageFilePicker"),
        wsImage : () => import("@/components/UI/pdfViewer/wsImage"),
        wsPdfDialog : () => import("@/components/UI/pdfViewer/wsPdfDialog"),
        wsPdf : () => import("@/components/UI/pdfViewer/wsPdf"),
        wsSwitch : () => import("@/components/UI/wsSwitch"),
        aEditor : () => import("@/components/AvalonEditor/Editor/aEditor/AEditor"),
        subPage : () => import("@/components/pages/dashboard/UI/subPage"),
        pagePublic : () => import("@/components/pages/homepage/UI/pagePublic"),
        pageSection : () => import("@/components/pages/homepage_westudy/UI/pageSection"),
        wsDataTable : () => import("@/components/UI/tables/wsDataTable"),
        wsToggle : () => import("@/components/UI/wsToggle"),
        wsSelect : () => import("@/components/UI/wsSelect"),
        wsAddItem : () => import("@/components/UI/wsAddItem"),
        wsVideoViewer : () => import("@/components/UI/textViewer/wsVideoViewer"),
        wsTextViewer : () => import("@/components/UI/textViewer/wsTextViewer"),
        wsAccordion : () => import("@/components/UI/wsAccordion"),
        wsTextField : () => import("@/components/UI/wsTextField"),
        wsTextEditor : () => import("@/components/UI/textEditor/wsTextEditor"),
        wsNavigationHeader : () => import("@/components/UI/wsNavigationHeader"),
        wsVideoEditor : () => import("@/components/UI/videoPlayer/wsVideoEditor"),
        wsVideo : () => import("@/components/UI/videoPlayer/wsVideo"),
        wsEmbed : () => import("@/components/UI/embedVideo/wsEmbed"),
        wsEmbedEditor : () => import("@/components/UI/embedVideo/wsEmbedEditor"),
        wsFileUploader : () => import("@/components/UI/wsFileUploader"),
        wsIconButton : () => import("@/components/UI/wsIconButton"),
        wsTableHeader : () => import("@/components/UI/tables/wsTableHeader"),
        dashPageNew : () => import("@/components/pages/dashboard/UI/dashPageNew"),
        wsDialog : () => import("@/components/pages/dashboard/UI/modal/wsDialog"),
        wsTranslationField : () => import("@/components/UI/header/wsTranslationField"),
        wsEditMenu : () => import("@/components/UI/wsEditMenu"),
        wsTimePicker : () => import("@/components/UI/wsTimePicker"),
        wsDatePicker : () => import("@/components/UI/wsDatePicker"),
        wsPhoneInput : () => import("@/components/UI/wsPhoneInput"),
        ftSelect : () => import("@/components/UI/ftSelect"),
        dashPage : () => import("@/components/pages/dashboard/UI/dashPage"),
        wsChip : () => import("@/components/UI/wsChip"),
        wsColorPicker : () => import("@/components/UI/wsColorPicker"),
        wsCheckBox : () => import("@/components/UI/wsCheckBox"),
        wsPlainTextEdit : () => import("@/components/UI/wsPlainTextEdit"),
        wsTooltip : () => import("@/components/UI/wsTooltip")
    },
    data() {
        return {
            BUSINESS_STATUS_EXPIRED : 3,
            SOCIALS_SELECT : [
                { text : "Telegram"  , value : "telegram" },
                { text : "Instagram" , value : "instagram"},
                { text : "Facebook"  , value : "facebook" },
                { text : "Youtube"   , value : "youtube"  },
                { text : "Whatsapp"  , value : "whatsapp" },
                { text : "LinkedIn"  , value : "linkedin" },
                { text : "Viber"     , value : "viber"    },
                { text : "Tiktok"    , value : "tiktok"   },
                { text : "X"   , value : "x"  },
            ],

            FONT_PAIRS_SELECT : [
                { heading : 'Montserrat' , paragraph : 'Montserrat' },
                { heading : 'Lora' , paragraph : 'Noto Sans' },
                { heading : 'Oswald' , paragraph : 'Nunito Sans' },
                { heading : 'Tenor Sans' , paragraph : 'Nunito Sans' },
                { heading : 'Unbounded' , paragraph : 'Raleway' },
                { heading : 'Playfair Display' , paragraph : 'Inter' },
                { heading : 'EB Garamond' , paragraph : 'Raleway' },
                { heading : 'Commissioner' , paragraph : 'Alegreya Sans' },
            ],

            CLIPPING_MASKS_ARRAY : [
                "svgMaskSquare",
                "svgMaskCircle",
                "svgMaskSand" ,
                "svgMaskStop",
                "svgMaskParallel",
                "svgMaskEllipse",
                "svgMaskQuadroEllipse",
                "svgMaskTrapezoid",
                "svgMaskDoubleElipse",
                "svgMaskHalfRounded",
                "svgMaskTopRounded",
                "svgMaskSemiEllipseRight",
                "svgMaskSemiEllipseTop",
                "svgMaskHouse",
                "svgMaskVerticalCapsule"
            ],
            CLIPPING_MASKS_RATIO : {
                "svgMaskSquare" : 1,
                "svgMaskCircle" : 1,
                "svgMaskSand" : 1,
                "svgMaskStop" : 1,
                "svgMaskParallel" : 5/6,
                "svgMaskEllipse" : 2/3,
                "svgMaskQuadroEllipse" : 2/3,
                "svgMaskTrapezoid" : 2/3,
                "svgMaskDoubleElipse" : 2/3,
                "svgMaskHalfRounded" : 2/3,
                "svgMaskTopRounded" : 2/3,
                "svgMaskSemiEllipseRight" : 2/3 ,
                "svgMaskSemiEllipseTop" : 46/49,
                "svgMaskHouse" : 1,
                "svgMaskVerticalCapsule" : 36/54
            },

            SUBTITLES_FORMATS : ['vtt','srt'],





            pink_1             : "#393c65",
            pink_2             : "#262443",
            pink_3             : "#4b5797",
            pink_4             : "#1d1d36",
            green_1            : "#7AB971",
            green_2            : "#44655b",

            colors_fine : {
                wsACCENT      : "#5e55bf",
                wsDARK        : "#262443",
                wsDARKLIGHT   : "#4b5797",
                wsDARKER      : "#1d1d36",
                wsBACKGROUND  : "#edeeef",
                wsLIGHTACCENT : "#afafaf",
                wsLIGHTCARD   : "#f8f8f8",
                wsSUCCESS     : "#7AB971",
                wsSUCCESSDARK : "#44655b",
                wsLIGHTSUCCESS: "#DDF5CD",
                wsATTENTION   : "#e61743",
                wsWARNING   : "#fe4b4b",
            },

            colors_avalon : {
                wsACCENT      : "#393c65",
                wsDARK        : "#262443",
                wsDARKLIGHT   : "#4b5797",
                wsDARKER      : "#1d1d36",
                wsBACKGROUND  : "#edeeef",
                wsLIGHTACCENT : "#afafaf",
                wsLIGHTCARD   : "#f8f8f8",
                wsSUCCESS     : "#7AB971",
                wsSUCCESSDARK : "#44655b",
                wsLIGHTSUCCESS: "#DDF5CD",
                wsATTENTION   : "#e61743",
                wsWARNING   : "#fe4b4b",
            },
            COLORS_LABELS : [
                "var(--wsDARK)"           ,
                "var(--wsDARKER)"         ,
                "var(--wsDARKLIGHT)"      ,
                "var(--wsSUCCESS)"        ,
                "var(--wsATTENTION)"      ,
                "var(--wsWARNING)"        ,
                "var(--wsSUCCESSDARK)"    ,
                "var(--pink_1)"           ,
                "var(--pink_2)"           ,
                "var(--pink_3)"           ,
                "var(--pink_4)"           ,

            ],

            colors_westudy : this.$store.state.colors,



        }
    },
    computed: {

        ...mapState('templates', [  'businessAlias', 'editorMode', 'menuItems' , 'templateVars_init', 'templateVars_current' , 'headerDark']),

        IS_ELITE_PLUS() {
            if (!this.$store.state.business.selectedBusiness) {
                return false
            }

            if (!this.$store.state.business.selectedBusiness.business_plan_alias) {
                return false
            }

            return this.$store.state.business.selectedBusiness.business_plan_alias.includes('elite_plus')


        },

        IS_WSU() {
            return process.env.VUE_APP_PROJECT === 'university';
        },
        BUSINESS_SUSPENDED() {
            return this.$store.state.ajax.error && this.$store.state.ajax.error.toString().includes('Business account suspended')
        },
        USER_SUSPENDED() {
            return this.$store.state.ajax.error && this.$store.state.ajax.error.toString().includes('User account suspended')
        },
        BUSINESS_EXPIRED() {
            if ( !this.$store.state.business.selectedBusiness) {
                return false
            }
            if ( !this.$store.state.business.selectedBusiness.subscription_status) {
                return false
            }
            if ( this.$store.state.business.selectedBusiness.subscription_status === 3) {
                return true
            }
            return false
        },

        HAS_ONLINE_PAYMENT() {

            if ( this.$store.state.business.selectedBusiness.payments && this.$store.state.business.selectedBusiness.payments.length ) {
                let onlinePayments = this.$store.state.business.selectedBusiness.payments.filter(el=>el.id.includes('online') )
                if ( onlinePayments.length > 0 ) {
                    return true
                }
            }
            if ( this.$store.state.cart.payments && this.$store.state.cart.payments.length > 0 ) {
                let onlinePayment = this.$store.state.cart.payments.filter(el=>el.value.includes('online') )
                if ( onlinePayment.length > 0 ) {
                    return true
                }
            }
            return false
        },
        GET_ONLINE_PAYMENT() {

            if ( this.$store.state.business.selectedBusiness.payments && this.$store.state.business.selectedBusiness.payments.length ) {
                let onlinePayments = this.$store.state.business.selectedBusiness.payments.filter(el=>el.id.includes('online') )
                if ( onlinePayments.length > 0 ) {
                    return onlinePayments[0].id
                }
            }

            if ( this.$store.state.cart.payments && this.$store.state.cart.payments.length > 0 ) {
                let onlinePayment = this.$store.state.cart.payments.filter(el=>el.value.includes('online') )
                if ( onlinePayment.length > 0 ) {
                    return onlinePayment[0].value
                }
            }
            return null
        },
        EDITOR_MODE() {
            return this.$store.state.templates.editorMode
        },
        BUSINESS_NAME() {
            return this.$store.state.business.selectedBusiness ?  this.$store.state.business.selectedBusiness.name : null
        },

        BUSINESS_EMAIL_CLIENT_ADDRESS() {
            return this.$store.state.business.selectedBusiness ?  this.$store.state.business.selectedBusiness.emailClientAddress : null
        },

        SHIFT_DOWN() {
            return this.$store.state.shiftDown
        },

        SM() {
            if ( this.DEVICE === 'mobile') {
                return true
            }
            return this.displayShow(['sm'])
        },
        IS_MOBILE() {
            return this.DEVICE === 'mobile'
        },

        localeUrl() {
            return this.$i18n.locale === process.env.VUE_APP_I18N_LOCALE ? '' : `${this.$i18n.locale}/`
        },

        windowSize()  {
            let breakPoint = this.$vuetify.breakpoint.name
            if ( breakPoint === 'xs' ) { breakPoint = 'sm' }
            if ( breakPoint === 'xl' ) { breakPoint = 'lg' }
            return breakPoint
        },
        DEVICE() {
            let device = 'desktop'

            if ( this.$device.mobile ) {
                device = "mobile"
            }
            return device
        },
        PARAMS_languages() {
            if ( (this.$store.state.avalon.viewAvalon || this.$store.state.avalon.editorMode) && !this.$store.state.isHomepage ) {
                return this.$store.state.avalon.langs
            }
            return this.$store.state.languages
        },
        TINY_MCE() {
            return process.env.VUE_APP_TINY_MCE
        },
        TINY_MCE_LANG() {
            switch ( this.$i18n.locale ) {
                case 'ua': return 'uk'
                case 'cn': return 'zh_CN'
                default : return this.$i18n.locale
            }
        },
        LANG() {
            return this.$i18n.locale
        },
        LOADING() {
            return this.$store.state.LOADING
        },

        //TYPES
        TASK_TYPES() {
            return [
                { text : this.$t('Assignment'),   value: 'assignment' },
                { text : this.$t('Test'),         value: 'test'       },
             //   { text : this.$t('VideoMeeting'), value: 'video'      },
            ]
        },
        TASK_VIDEO_TYPES() {
            return [
                { text : 'Zoom'   ,     value: 'zoom'      },
                { text : 'Google Meets',  value: 'google'    },
                { text : 'Microsoft Teams', value: 'microsoft' },
            ]
        },

        is_westudy() {
            return true
            // return [this.$store.state.second_hostname, "www." + this.$store.state.second_hostname].includes(window.location.host)
        },
        is_avalon() {
            return this.$store.state.avalon.editorMode || false
        },

        CHOICE_ACTIVE() {
            return [
                { text : this.$t('Active') , value: true },
                { text : this.$t('NotActive') , value: false }
            ]
        },
        CHOICE_PUBLISHED() {
            return [
                { text : this.$t('IsPublished') , value: true },
                { text : this.$t('NotPublished') , value: false }
            ]
        },
        CHOICE_ACTIVE_SELECT() {
            return [
                { text : this.$t('All')    , value: null },
                { text : this.$t('Active') , value: true },
                { text : this.$t('NotActive') , value: false }
            ]
        },
        CHOICE_COMPLETION_SELECT() {
            return [
                { text : this.$t('All')      , value: null },
                { text : this.$t('Passed')   , value: true },
                { text : this.$t('NoResult') , value: false }
            ]
        },


        CHOICE_COMPLETED() {
            return [
                { text : this.$t('Completed')    , value: true },
                { text : this.$t('NotCompleted') , value: false }
            ]
        },
        CHOICE_COMPLETED_SELECT() {
            return [
                { text : this.$t('All')    , value: null },
                { text : this.$t('Completed')    , value: true },
                { text : this.$t('NotCompleted') , value: false }
            ]
        },
        CHOICE_PAYED() {
            return [
                { text : this.$t('PaymentConfirmed') , value: true },
                { text : this.$t('PaymentNotConfirmed') , value: false }
            ]
        },
        CHOICE_PAYED_SELECT() {
            return [
                { text : this.$t('All')    , value: null },
                { text : this.$t('PaymentConfirmed') , value: true },
                { text : this.$t('PaymentNotConfirmed') , value: false }
            ]
        },
        userLang() {
            return this.$store.state.auth.userLang
        },
        EVALUATION_NAVIGATION() {
            return [
                { text : this.$t('Materials')    ,  value : 'components'    , path : 'components'    , icon : "mdi-text-box-check-outline" },
                { text : this.$t('Students')      ,  value : 'students'      , path : 'students'      , icon : "mdi-account-multiple" },
                { text : this.$t('NewReplies') , counter : this.$store.state.courses.courseNewRepliesCounter , value : 'events' , path : 'events' , icon : "mdi-alert-decagram" },
            ]
        },
        IS_ROUTE_CHANGED() {
            return this.$store.state.isRouteChanged
        },

    },
    watch : {
        userLang(value) {
            if ( value ) {
                this.LOCALE_CHANGE(value)
            }
        }
    },
    methods : {




        PLURIZE_WORD(word) {
            if (word.endsWith('y')) {
                return word.slice(0, -1) + 'ies';
            } else {
                return word + 's';
            }
        },
        CAPITALIZA_FIRST_CHAR(string) {
            return string[0].toUpperCase() + string.slice(1)
        },
        NO_LANG_PATH() {
            let path = this.$route.path
            let lang = this.$route.params.lang

            if (path.includes(lang)) {
                path = path.replace(`${lang}/`, '')
            }
            this.notify(lang + '==>' + path , 'error')
            return path
        },
        NUMBER_WITH_COMA(number) {
            let str = number.toString();
            return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        REFRESH_TEXT_FIELD() {
            this.$nextTick(() => {
                const textArea = this.$refs.textarea;
                if ( textArea) {
                    textArea.dispatchEvent(new Event('input'));
                }
            });
        },

        VALIDATE_URL(url) {
            const urlRegex = /^(https?:\/\/)[^\s/$.?#].[^\s]*$/i;
            return urlRegex.test(url);
        },
        VALIDATE_EMAIL(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        async CLIPBOARD(text , message = null ) {
            try {
                await navigator.clipboard.writeText(text);
                this.notify(message || this.$t('CopiedToClipboard') )
            } catch($e) {
                this.notify(this.$t('Error') )
            }
        },

        ERROR(error = 'NetworkError') {
            this.notify(this.$t(error) , 'error')
        },
        WESTUDY_ICON(icon) {
            return `${location.protocol}//${this.$store.state.iconSetUrl}${icon}.svg`
        },
        IS_WESTUDY_ICON(url) {
            if ( !url ) {
                return false
            }
            return url.includes(this.$store.state.iconSetUrl)
        },
        GET_CLIPPING_MASK(mask , ratio ) {
            let style = ''

            if ( !mask ) {
                return style
            }

            style = `clip-path: url( #${mask}); -webkit-clip-path: url("#${mask}");  background-size: cover;  `


            if ( ratio ) {
                style +=  `aspect-ratio : ${this.CLIPPING_MASKS_RATIO[mask]}`
            }

            return style

        },

        SHORTEN_FILE_NAME(fileName ) {
            let text = fileName
            if (fileName.length > 35 ) {
                 text = fileName.substring(0,17) + '...' + fileName.slice(-10)
            }
            return text
        },

        displayHide(sizes) {
            return !sizes.includes(this.windowSize )
        },
        displayShow(sizes) {
            return sizes.includes(this.windowSize )
        },
        TEMPLATES_GET_ELEMENT(element) {

            if ( this.$store.state.templates.templatesConfig[this.$store.state.templates.businessTemplate ].elements.includes(element) ) {
                return this.$store.state.templates.businessTemplate + element
            } else {
                return 'avalon' + element
            }

        },
        TEMPLATES_GET_PARAM(varName) {
                let param = '';
                if (  this.$store.state.templates.editorMode ) { param = this.$store.state.templates.templateVars_current[varName].value }
                if ( !this.$store.state.templates.editorMode ) { param = this.$store.state.templates.templateVars_init[varName]    }
                return param
        },
        TEMPLATES_ACCENT_COLOR() {
            return this.TEMPLATES_GET_PARAM('accent_color')
        },
        TEMPLATES_flexAlignment(alignment) {
            switch(alignment) {
                case 'left' : return 'justify-space-center'
                case 'center' : return 'justify-center'
                case 'right' : return 'justify-end'
                case 'justify' : return 'justify-space-between'
                default : return ''
            }
        },
        PARSE_NEW_LINES(input) {
            if ( !input ) {
                return ''
            }
            return input.replace(/\n/g,'<br>')
        },

        businessDashLink(route , admin = false , fullUrl = false) {
            var domain = 'https://westudy.ua'
            if ( this.$store.state.domain ) {
                domain = 'https://' + this.$store.state.business.selectedBusiness.domain
            }
            if (admin) {
                if ( !route ) {
                    let result =  `/${this.localeUrl}dashboard/`
                    if ( fullUrl ) {
                        result = domain + result
                    }
                    return result
                }
                let result =  `/${this.localeUrl}dashboard/` + route
                if ( fullUrl ) {
                    result = domain + result
                }
                return result
            }
            if (!route) {
                let result =  `/${this.localeUrl}dashboard/${this.$store.state.domain ? ''  : this.$store.state.business.selectedBusiness.alias}`
                if ( fullUrl ) {
                    result = domain + result
                }
                return result
            }
            let result =  `/${this.localeUrl}dashboard/${this.$store.state.domain ? ''  : (this.$store.state.business.selectedBusiness.alias + '/' )}` + route

            if ( fullUrl ) {
                result = domain + result
            }
            return result

        },
        businessPublicLink(route, fullUrl = false ) {
            var domain = this.$store.state.appUrl
            if ( this.$store.state.domain ) {
                domain = 'https://' + this.$store.state.business.selectedBusiness.domain
            }

            if (!route) {
                let result =  `/${this.localeUrl}${this.$store.state.domain ? ''  : this.$store.state.business.selectedBusiness.alias}`

                if ( fullUrl ) {
                    result = domain + result
                }
                return result
            }
            let result =  `/${this.localeUrl}${this.$store.state.domain ? ''  : (this.$store.state.business.selectedBusiness.alias + '/')}` + route

            if ( fullUrl ) {
                result = domain + result
            }
            return result

        },

        localeLink(path , fullUrl = false , noLang = false){
            let domain = this.$store.state.appUrl
            let link = ''
            if ( !noLang ) {
                link = `/${this.localeUrl}${path}`
            } else {
                link = `/${path}`
            }

            if ( fullUrl ) {
                link = domain + link
            }
            return link
        },
        // data valiadation

        NUMBER_INPUT(input,data,length, dec = false) {
            if ( !data ) {
                data = ''
            }
            input = (input) ? input : window.event;
            var charCode = (input.which) ? input.which : input.keyCode;
            let condition =    (((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 ) ||
                ( data.length >= length && charCode !== 8  )) && charCode !== 8

            console.log("charCode: " , charCode , condition)

            if (
                (((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 ) ||
                ( data.length >= length && charCode !== 8  )) && charCode !== 8
            ) {
                if ( dec && charCode === 190) { return true }
                input.preventDefault();
            } else {
                return true;
            }


        },

        COURSE_NAVIGATION_ADMIN(course = null) {
            let items =   [
                { text : this.$t('Content'),     value : 'editor'                , path : 'editor'                 , icon : 'mdi-table-of-contents' ,  permission:'MODULE_WESTUDY__EDITOR', },
                { text : this.$t('Description'), value : 'description'           , path : 'description'            , icon : 'mdi-image-text'        ,  permission:'MODULE_WESTUDY__SETTINGS',},
                { text : this.$t('Students'),    value : 'students'              , path : 'students'               , icon : 'mdi-account-multiple'  ,  permission:'MODULE_WESTUDY__SETTINGS',  },
                { text : this.$t('Evaluation'),  value : 'evaluation/components' , counter : this.$store.state.courses.courseNewRepliesCounter, path : 'evaluation/components'  , icon : 'mdi-text-box-check-outline' , permission:'MODULE_WESTUDY__EVALUATION',    },
            ]
            if (course) {
                if ( course.online && course.scheduled ) {
                    items.push( { text : this.$t('StudySchedule'),  value : 'calendar' , path : 'calendar' , icon : 'mdi-calendar' ,  permission:'MODULE_WESTUDY__SETTINGS', } )
                }
                if ( course.payed && course.has_payment_plans ) {
                    items.push( { text : this.$t('PaymentPlans'),  value : 'payment_plans' , path : 'payment_plans', icon : 'mdi-cash-multiple' ,  permission:'MODULE_WESTUDY__SETTINGS', } )
                    // items.push( { text : this.$t('PaymentPlansEditor'),  value : 'payment_plans/editor', path : 'payment_plans/editor' , icon : 'mdi-cash-multiple' } )
                }
                if ( !course.online   ) {
                    items.push( { text : this.$t('StudySchedule'),  value : 'offline_schedule' , path : 'offline_schedule',  icon : 'mdi-calendar' ,  permission:'MODULE_WESTUDY__SETTINGS', } )
                }

            }

            items.push( { text : this.$t('QuestionBanks') , value : 'question_banks' , path : 'question_banks' , icon : 'mdi-frequently-asked-questions'  ,  permission:'MODULE_WESTUDY__EDITOR', })

            if (course) {
                if (course.has_comments) {
                    items.push({text: this.$t('Comments'), value: 'comments', path: 'comments', icon: 'mdi-forum'})
                }
                if (course.has_certificates) {
                    items.push({
                        text: this.$t('Certificates'),
                        value: 'certificates',
                        path: 'certificates',
                        icon: 'mdi-certificate-outline',
                        permission: 'MODULE_WESTUDY__SETTINGS',
                    })
                }
            }
            items.push( { text : this.$t('Settings'), value : 'settings', path : 'settings', icon : 'mdi-cog' ,   permission:'MODULE_WESTUDY__SETTINGS', } )

            return items
        },
        COURSE_NAVIGATION_RETURN_LINK(course = null) {
            let items = this.COURSE_NAVIGATION_ADMIN(course)
            items = items.filter(el => !el.permission || this.MODULE_PERMISSIONS([el.permission], true))
            items = items.filter( el=> !el.auth   || el.auth.includes(this.userType)  )
            if ( items.length === 0 ) {
                return this.businessDashLink('courses')
            }
            return this.businessDashLink('courses/' + course.uuid + '/' + items[0].path )
        },

        async LOCALE_CHANGE(lang) {
            await this.$i18n.$loadLanguage(lang)
            if ( localStorage.getItem('locale') !== lang ) {
                 localStorage.setItem('locale',lang)
            }
            this.$i18n.locale = lang
        },

        //technical
        TASK_TYPE_ICON(type) {
            switch(type) {
                case 'assignment' :  return 'mdi-text-box-check-outline' ;
                case 'test'       :  return 'mdi-format-list-checks' ;
                case 'video'      :  return 'mdi-cast-education' ;
                default : return 'mdi-cog'
            }
        },

        init_colors( colorSet ) {

            Object.keys(colorSet).forEach((key) => {
                this[key] = colorSet[key]
            })

        },
        TEMPLATE_VAR(varName) {
            let param = '';
            if (this.editorMode) {
                param = this.templateVars_current[varName] ? this.templateVars_current[varName].buffer : null
            }
            if (!this.editorMode) {
                param = this.templateVars_init[varName]
            }
            return param
        },

        PARSE_FILE_SIZE(size = 0) {
            size = parseInt(size) || 0

            let result = ( size/1000/1000).toFixed(2)
            result += ' MB'
            return result
        },
        capitalizeFirstChar(string) {
            return string[0].toUpperCase() + string.slice(1)
        },

        EQ( a, b ) {

            a = !a ? null : JSON.stringify(a)
            b = !b ? null : JSON.stringify(b)

            return a === b
        },
        COPY(object) {
            if ( !object ) {
                return null
            }
            return JSON.parse(JSON.stringify(object))
        }

    },
    mounted() {
        // init color scheme
        document.body.style.backgroundColor = '#ffffff';


    }
}