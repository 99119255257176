const actions = {

    async ADD_BUSINESS_INTEGRATION_TOKEN({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`integrations/token`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            },  {root:true});
    },

    async GET_BUSINESS_INTEGRATION_TOKENS({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`integrations/tokens`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },

    async DELETE_BUSINESS_INTEGRATION_TOKEN({dispatch,rootState}, body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`integrations/token/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },

    async ADD_BUSINESS_INTEGRATION_WEBHOOK({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`webhook`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            },  {root:true});
    },

    async GET_BUSINESS_INTEGRATION_WEBHOOKS({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`webhooks`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },

    async DELETE_BUSINESS_INTEGRATION_WEBHOOK({dispatch,rootState}, body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`webhook/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },





}

export default {
    namespaced:true,
    actions,
}